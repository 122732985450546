// npm
import React, { memo } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { ButtonProps } from '@mui/material'
import loadable from '@loadable/component'

import Button from '@atoms/buttons/Button'

const TextButton = loadable(() => import('@atoms/buttons/TextButton'))

// helpers
import slugPrefix, { slugPrefixProps } from '@helpers/slugPrefix'

export interface InternalButtonLinkProps extends ButtonProps, slugPrefixProps {
  children: React.ReactNode
  buttonVariant?: 'standard' | 'text'
}

const InternalButtonLink = ({
  slug,
  apiKey,
  buttonVariant,
  children,
  ...props
}: InternalButtonLinkProps) => {
  if (buttonVariant && buttonVariant === 'text') {
    return (
      <TextButton
        // @ts-ignore
        to={slugPrefix({ slug: slug, apiKey: apiKey })}
        component={GatsbyLink}
        {...props}
      >
        {children}
      </TextButton>
    )
  } else {
    return (
      <Button
        // @ts-ignore
        to={slugPrefix({ slug: slug, apiKey: apiKey })}
        component={GatsbyLink}
        {...props}
      >
        {children}
      </Button>
    )
  }
}
export default memo(InternalButtonLink)
